import { Contact } from "../type/contact";
import { buildSchema } from "@camberi/firecms";

export const contactSchema = buildSchema<Contact>({
  name: "Contact",
  customId: true,
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    avatar: {
      title: "Avatar",
      dataType: "string",
    },
    currentRole: {
      title: "Current Role",
      dataType: "string",
    },
    previousRole: {
      title: "Previous Role",
      dataType: "string",
    },
    currentCity: {
      title: "Current City",
      dataType: "string",
    },
    email: {
      title: "Email",
      dataType: "string",
    },
    linkedin: {
      title: "LinkedIn",
      validation: { required: true },
      dataType: "string",
    },
    whatsapp: {
      title: "WhatsApp",
      dataType: "string",
    },
  },
});
