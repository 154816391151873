import React from "react";
import algoliasearch, { SearchClient } from "algoliasearch";

import { User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
  performAlgoliaTextSearch,
  FirestoreTextSearchController,
} from "@camberi/firecms";
import { roleSchema } from "./schema/role";
import { companySchema } from "./schema/company";
import { hiringManagerSchema } from "./schema/hiring-manager";

import "typeface-rubik";
import "typeface-space-mono";
import { contactSchema } from "./schema/contact";
import { scoutSchema } from "./schema/scout";
import { candidateSchema, idealCandidateSchema } from "./schema/candidate";

const client: SearchClient | undefined = algoliasearch(
  "18PM3W5FB4",
  "3d794911e1b62a742f34530d429c7ff1"
);

const contactsIndex = client.initIndex("teero");
const scoutsIndex = client.initIndex("scouts");
const idealCandidatesIndex = client.initIndex("ideal_candidates");
const hiringManagersIndex = client.initIndex("hiring_managers");
const rolesIndex = client.initIndex("roles");
const companiesIndex = client.initIndex("companies");

const textSearchController: FirestoreTextSearchController = ({
  path,
  searchString,
}) => {
  if (path === "contacts") {
    return performAlgoliaTextSearch(contactsIndex, searchString);
  }
  if (path === "scouts") {
    return performAlgoliaTextSearch(scoutsIndex, searchString);
  }
  if (path === "ideal_candidates") {
    return performAlgoliaTextSearch(idealCandidatesIndex, searchString);
  }
  if (path === "hiring_managers") {
    return performAlgoliaTextSearch(hiringManagersIndex, searchString);
  }
  if (path === "roles") {
    return performAlgoliaTextSearch(rolesIndex, searchString);
  }
  if (path === "companies") {
    return performAlgoliaTextSearch(companiesIndex, searchString);
  }
  return undefined;
};

// TODO: Replace with your config
const firebaseConfig = {
  apiKey: "AIzaSyCFn2DWhwYIiqBFw18r6FziSgnLMkykocs",
  authDomain: "teero-3e608.firebaseapp.com",
  projectId: "teero-3e608",
  storageBucket: "teero-3e608.appspot.com",
  messagingSenderId: "476801737941",
  appId: "1:476801737941:web:80f4edb73aae82dddd2e5c",
  measurementId: "G-4X8Y06RE7Q",
};

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        buildCollection({
          path: "roles",
          schema: roleSchema,
          textSearchEnabled: true,
          name: "Roles",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection({
          path: "companies",
          schema: companySchema,
          textSearchEnabled: true,
          name: "Companies",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection({
          path: "contacts",
          schema: contactSchema,
          textSearchEnabled: true,
          name: "Contacts",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection({
          path: "hiring_managers",
          schema: hiringManagerSchema,
          textSearchEnabled: true,
          name: "Hiring Managers",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection({
          path: "candidates",
          schema: candidateSchema,
          name: "Candidates",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection({
          path: "ideal_candidates",
          schema: idealCandidateSchema,
          textSearchEnabled: true,
          name: "Ideal Candidates",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection({
          path: "scouts",
          schema: scoutSchema,
          textSearchEnabled: true,
          name: "Scouts",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    // You can throw an error to display a message
    const allowedUsers = [
      "d.maykov@gmail.com",
      "christian.wicks@gmail.com",
      "nathaniel.david.anderson@gmail.com",
      "idnddd@gmail.com",
    ];
    console.log(user);
    if (!user || !user.email) {
      throw Error("Access not allowed");
    }
    if (!allowedUsers.includes(user.email!)) {
      throw Error("Access not allowed");
    }

    console.log("Allowing access to", user?.email);
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: ["admin"],
    });
    authController.setExtra(sampleUserData);
    return true;
  };

  return (
    <FirebaseCMSApp
      name={"Teero"}
      authentication={myAuthenticator}
      textSearchController={textSearchController}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
    />
  );
}
