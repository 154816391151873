import { Candidate, IdealCandidate } from "../type/candidate";
import { buildProperty, buildSchema } from "@camberi/firecms";

export const candidateSchema = buildSchema<Candidate>({
  name: "Candidates",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    contactRef: buildProperty({
      title: "Contact",
      dataType: "reference",
      path: "contacts",
    }),
    roleRef: buildProperty({
      title: "Role",
      dataType: "reference",
      path: "roles",
    }),
    scoutNotes: {
      title: "Scout Notes",
      validation: { required: true },
      dataType: "string",
      config: {
        multiline: true,
      },
    },
    scoutRef: {
      title: "Scout",
      validation: { required: true },
      dataType: "reference",
      path: "scouts",
    },
    hiringManagerFeedback: {
      title: "HM Feedback",
      validation: { required: true },
      dataType: "string",
    },
    hiringManagerReview: {
      title: "HM Review",
      validation: { required: true },
      dataType: "string",
      config: {
        enumValues: {
          "Pending Decision": "Pending Decision",
          "Not Interested": "Not Interested",
          "Intro Requested": "Intro Requested",
        },
      },
    },
    scoutStatus: {
      title: "Scout Status",
      validation: { required: true },
      dataType: "string",
      config: {
        enumValues: {
          Pending: "Pending",
          "Not Interested": "Not Interested",
          "Gauging Interest": "Gauging Interest",
          "Intro Made": "Intro Made",
        },
      },
    },
    uid: {
      title: "Firebase User ID",
      validation: { required: true },
      dataType: "string",
    },
    submissionDate: {
      title: "Submission Time",
      dataType: "timestamp",
    },
  },
});

export const idealCandidateSchema = buildSchema<IdealCandidate>({
  name: "Ideal Candidates",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    contactRef: buildProperty({
      title: "Contact",
      dataType: "reference",
      path: "contacts",
    }),
    idealCandidateNotes: {
      title: "Ideal Candidate Notes",
      dataType: "string",
      config: {
        multiline: true,
      },
    },
  },
});
