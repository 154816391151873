
import { Scout } from "../type/scout";
import {
    buildProperty,
    buildSchema,
  } from "@camberi/firecms";


export const scoutSchema = buildSchema<Scout>({
    name: "Scouts",
    properties: {
      name: {
        title: "Name",
        dataType: "string"
      },
      contactRef:buildProperty({
        title: "Contact",
        dataType: "reference",
        path: "contacts",
      })
    }
  });