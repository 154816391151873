import { Role } from "../type/role";
import { buildProperty, buildSchema } from "@camberi/firecms";

export const roleSchema = buildSchema<Role>({
  name: "Role",
  properties: {
    title: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    companyRef: buildProperty({
      title: "Company",
      dataType: "reference",
      path: "companies",
    }),
    isScoutSubmissionRole: buildProperty({
      title: "Is Scout Role",
      dataType: "boolean",
    }),
    hiringManagerRef: buildProperty({
      title: "Hiring Manager",
      dataType: "reference",
      path: "hiring_managers",
    }),
    idealCandidatesRef: buildProperty({
      title: "Ideal Candidates",
      dataType: "array",
      of: {
        title: "Ideal Candidates",
        dataType: "reference",
        path: "ideal_candidates",
      },
    }),
    hiringTeamRef: buildProperty({
      title: "Hiring Team",
      dataType: "array",
      of: {
        title: "Hiring Team",
        dataType: "reference",
        path: "hiring_managers",
      },
    }),
    scoutsRef: buildProperty({
      title: "Scouts",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "scouts",
      },
    }),
    status: {
      title: "Status",
      validation: { required: true },
      dataType: "string",
      config: {
        enumValues: {
          Open: "Open",
          Closed: "Closed",
        },
      },
    },
    audio: {
      title: "Audio Link (Deprecated)",
      dataType: "string",
    },
    audios: {
      title: "Audios",
      dataType: "array",
      of: buildProperty({
        title: "Audio",
        dataType: "map",
        properties: {
          title: {
            title: "Title",
            validation: { required: true },
            dataType: "string",
          },
          link: {
            title: "Audio Link",
            validation: { required: true },
            dataType: "string",
          },
        },
      }),
    },
    description: {
      title: "Job Description",
      validation: { required: true },
      dataType: "string",
      config: {
        markdown: true,
      },
    },
    keywords: {
      title: "Keywords",
      validation: { required: true },
      dataType: "string",
    },
    isRemote: {
      title: "Is Remote",
      dataType: "boolean",
    },
    country: {
      title: "Country",
      validation: { required: true },
      dataType: "string",
    },
    city: {
      title: "City",
      validation: { required: true },
      dataType: "string",
    },
    currency: {
      title: "Currency",
      dataType: "string",
    },
    salary: buildProperty({
      title: "Salary",
      dataType: "map",
      properties: {
        min: {
          title: "Min Salary",
          dataType: "string",
        },
        max: {
          title: "Max Salary",
          dataType: "string",
        },
      },
    }),
    equity: {
      title: "Equity",
      dataType: "string",
    },
    scoutReward: {
      title: "Bounty",
      validation: { required: true },
      dataType: "string",
    },
    jobType: {
      title: "Job Type",
      validation: { required: true },
      dataType: "array",
      of: {
        dataType: "string",
        config: {
          enumValues: {
            "Full Time": "Full Time",
            "Part Time": "Part Time",
            "Freelance / Consultant": "Freelance / Consultant",
          },
        },
      },
    },
    visa: {
      title: "Visa Sponsorship",
      dataType: "string",
      config: {
        enumValues: {
          Available: "Available",
          Unavailable: "Unavailable",
        },
      },
    },
    experience: {
      title: "Experience",
      dataType: "array",
      of: {
        dataType: "string",
        config: {
          enumValues: {
            Junior: "Junior",
            Senior: "Senior",
            "University Graduate": "University Graduate",
          },
        },
      },
    },
    languages: {
      title: "Languages",
      dataType: "array",
      of: {
        dataType: "string",
      },
    },
    skills: {
      title: "Helpful Skills / Behaviors",
      dataType: "array",
      of: {
        dataType: "string",
      },
    },
    process: {
      title: "Process",
      dataType: "array",
      of: buildProperty({
        title: "ProcessStep",
        dataType: "map",
        properties: {
          title: {
            title: "Step Title",
            validation: { required: true },
            dataType: "string",
          },
          description: {
            title: "Step Description",
            validation: { required: true },
            dataType: "string",
          },
        },
      }),
    },
  },
});
