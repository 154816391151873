import { HiringManager } from "../type/hiring-manager";
import { buildProperty, buildSchema } from "@camberi/firecms";

export const hiringManagerSchema = buildSchema<HiringManager>({
  name: "Hiring Managers",
  properties: {
    name: {
      title: "Name",
      dataType: "string",
    },
    contactRef: buildProperty({
      title: "Contact",
      dataType: "reference",
      path: "contacts",
      previewProperties: ["name"],
    }),
    hiringManagerNotes: {
      title: "Notes",
      dataType: "string",
    },
    uid: {
      title: "Firebase ID",
      dataType: "string",
    },
  },
});
